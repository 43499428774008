import React from "react";
import { Button } from 'react-bootstrap';
import './OneDayDatePick.css';
import leftArrow from '../../../../assets/arrow-left-solid.svg';
import rightArrow from '../../../../assets/arrow-right-solid.svg';

function OneDayDatePick(props) {

    const dayNames = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

    const currentDate = new Date(props.currentDate);

    const pickedDate = new Date(props.pickedDate);

    const currentCompareDate = currentDate.setHours(0,0,0,0);

    const pickedCompareDate = pickedDate.setHours(0,0,0,0);

    return (
        <div className='d-flex justify-content-center quick-reservation__date-pick'>
            {
                currentCompareDate === pickedCompareDate
                    ?
                    (
                        <div className='date-change-btn__arrow'></div>
                    )
                    :
                    (
                        <Button
                            className='date__date-change-btn'
                            onClick={props.decreaseDate}
                        >
                            <img src={leftArrow} alt="" className='date-change-btn__arrow'/>
                        </Button>
                    )
            }

            <div className='date-pick__date d-flex justify-content-around'>
                <div className='date__name'>
                    {
                        currentCompareDate === pickedCompareDate ? ('Heute') : (dayNames[props.pickedDate.getDay()])
                    }
                </div>
                <div className='date__numerical'>
                    {props.pickedDate.getDate() + "."}
                    {props.pickedDate.getMonth() + 1}
                    {"." + props.pickedDate.getFullYear()}
                </div>
            </div>
            <Button
                className='date__date-change-btn'
                onClick={props.increaseDate}
            >
                <img src={rightArrow} alt="" className='date-change-btn__arrow'/>
            </Button>
        </div>
    )
}

export default OneDayDatePick;