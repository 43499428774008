import React, {useEffect} from "react";
import {Link, useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';

function OrderFailed(props) {

    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem('guestId')) {
            history.push('/')
        }
    }, [history])

    return (
        <>
            <h2>Ooops!</h2>
            <h2>Something went wrong!</h2>
            <h4>Your order has failed.</h4>
            <p>Please check your payment details and try again later</p>
            <Link to='/'>
                <Button>Booking</Button>
            </Link>
        </>
    )
}

export default OrderFailed;