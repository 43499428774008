import React, { useState, useEffect } from "react";
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import useGetId from "./useGetId";
// import PickedSchedules from "./PickedSchedules";
// import lock from '../../../assets/unlock-alt-solid.svg';
// import clock from '../../../assets/clock-solid.svg';
// import people from '../../../assets/user-friends-solid.svg';

function OrderSuccess(props) {

    // const [userReservations, setUserReservations] = useState([]);
    const [reservationIds, setUserReservationIds] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [qrCode, setQrCode] = useState(null);

    const history = useHistory();

    const {guestId, loaded:idLoaded} = useGetId();

    useEffect(() => {
        if (!idLoaded) {
            return
        }

        if (!guestId) {
            console.log('User is not authorised!')
            history.push('/')
        }

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let graphql = JSON.stringify({
            query: "{    ownReservations(        guestId: " + guestId + "    ) {         id        roomId        times        status        day        count        altCount    }}",
            variables: {}
        })
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: graphql,
            redirect: 'follow'
        };

        fetch(props.url, requestOptions)
            .then(response => response.json())
            .then(result => {
                    if (result.errors) {
                        const messageArray = [];
                        result.errors.forEach(e => messageArray.push(e.message))
                        alert(messageArray);
                        return
                    }

                    console.log(result);
                    let unsorted = result.data.ownReservations;
                    // let sorted = unsorted.sort((a, b) => +a.id - +b.id);
                    // setUserReservations(sorted);
                    console.log(unsorted);
                    if (unsorted.length === 0) {
                        setUserReservationIds([])
                    } else {
                        unsorted.map(reservation => {
                            setUserReservationIds(prevState => [...prevState, reservation.id])
                        })
                    }
                }
            )
            .catch(error => alert(error))
            .finally(() => setLoaded(true))

    }, [guestId, idLoaded]);



    // function pushIdIntoQueryArray(event) {
    //     if (event.target.checked === true) {
    //         setUserReservationIds([...reservationIds, event.target.value]);
    //     } else {
    //         const newUserReservations = reservationIds;
    //         for (let i = 0; i < newUserReservations.length; i++) {
    //
    //             if (newUserReservations[i] === event.target.value) {
    //
    //                 newUserReservations.splice(i, 1);
    //             }
    //         }
    //         setUserReservationIds(newUserReservations);
    //     }
    // }

    function GenerateQR() {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let graphql = JSON.stringify({
            query: "mutation {    generateQR(        reservationIds: [" + reservationIds + "],        guestId: " + localStorage.getItem('guestId') + "    )}",
            variables: {}
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: graphql,
            redirect: 'follow'
        };

        fetch(props.url, requestOptions)
            .then(response => response.json())
            .then(result => {
                    if (result.errors) {
                        const messageArray = [];
                        result.errors.forEach(e => messageArray.push(e.message))
                        alert(messageArray);
                    } else {
                        setQrCode(result.data.generateQR);
                    }
                }
            )
            .catch(error => alert(error));
    }

    if (loaded) {
        return (
            <>
                {
                    reservationIds.length > 0
                    ?
                        <>
                            <h1>Vielen Dank!</h1>
                            <p>Sie haben Ihre Bestellung erfolgreich gebucht.</p>
                            <p>Sie können eine andere Bestellung buchen oder QR-Code für Ihre bestehenden Bestellungen
                                generieren.</p>
                            <p>Um einen QR-Code zu generieren, klicken Sie auf die Schaltfläche „QR generieren“.</p>
                            <p>Der QR-Code wird an Ihre E-Mail-Adresse gesendet.</p>
                            <div className='d-flex justify-content-center'>
                                <Link
                                    to='/'
                                    onClick={props.resetForNextBooking}
                                >
                                    <Button>Buchung</Button>
                                </Link>
                                <Button
                                    className='order-success-generate-btn'
                                    onClick={GenerateQR}
                                >
                                    QR generieren
                                </Button>
                            </div>
                        </>
                        :
                        <>
                            <h2>Du hast keine Reservierungen</h2>
                            <div className='mt-4'>
                                <Link
                                    to='/'
                                    onClick={props.resetForNextBooking}
                                >
                                    <Button>Buchung</Button>
                                </Link>
                            </div>
                        </>
                }

                {qrCode !== null && (
                    <div>
                        <h2>Ihr QR-Code:</h2>
                        <img src={qrCode} alt="qr-code"/>
                    </div>
                )

                }

                {/*{*/}
                {/*    reservationIds.length > 0*/}
                {/*    &&*/}
                {/*    (*/}
                {/*        <>*/}
                {/*            <h4 className='order-success-reservations-title'>Ihre Reservation:</h4>*/}
                {/*            <ul className='order-success-reservations-list'>*/}
                {/*                {userReservations.map(*/}
                {/*                    (reservation, index) =>*/}
                {/*                        <li key={index} className='d-flex justify-content-between list-single-item'>*/}
                {/*                            <Form.Check*/}
                {/*                                type='checkbox'*/}
                {/*                                value={reservation.id}*/}
                {/*                                onChange={pushIdIntoQueryArray}*/}
                {/*                                className='list-checkbox'*/}
                {/*                            />*/}
                {/*                            <div className='list-data'>*/}
                {/*                                <strong>ID:&nbsp;</strong>*/}
                {/*                                {reservation.id}*/}
                {/*                            </div>*/}
                {/*                            <div className='list-data'>*/}
                {/*                                <div className='d-flex align-items-center'>*/}
                {/*                                    <img className='list-data__icon' src={lock} alt=""/>*/}
                {/*                                    <strong>:&nbsp;</strong>*/}
                {/*                                </div>*/}
                {/*                                <div>*/}
                {/*                                    {props.rooms.find((room) => getRoomName(room, reservation.roomId)).name}*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className='list-data list-data-date'>*/}
                {/*                                <div className='d-flex align-items-center'>*/}
                {/*                                    <img className='list-data__icon' src={clock} alt=""/>*/}
                {/*                                    <strong>:&nbsp;</strong>*/}
                {/*                                </div>*/}
                {/*                                <div>*/}
                {/*                                    <PickedSchedules*/}
                {/*                                        id={reservation.id}*/}
                {/*                                        schedules={reservation.times}*/}
                {/*                                        rooms={props.rooms}*/}
                {/*                                    />*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className='list-data'>*/}
                {/*                                <div className='d-flex align-items-center'>*/}
                {/*                                    <img className='list-data__icon' src={people} alt=""/>*/}
                {/*                                    <strong>:&nbsp;</strong>*/}
                {/*                                </div>*/}
                {/*                                <div>*/}
                {/*                                    {reservation.count + reservation.altCount}*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </li>*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            </ul>*/}
                {/*        </>*/}
                {/*    )*/}
                {/*}*/}
            </>
        )
    } else {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )
    }
}

export default OrderSuccess;