import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import './AdminDashboard.css'

const AdminDashboard = () => {

    const history = useHistory();

    const logoutHandler = () => {
        localStorage.clear();
        history.push('/')
    };

    return (
        <Container>
            <Row>
                <Col>
                    <div className="list-wrap">
                        <Link className='btn btn-primary' to='/admin/create-qr-tool'>Create QR</Link>
                        <Button variant='secondary' onClick={logoutHandler}>Logout</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminDashboard;