import React from "react";
import {Button} from "react-bootstrap";
import increase from "../../../../assets/plus-solid.svg";
import decrease from "../../../../assets/minus-solid.svg";

function OneDayQuantityCounter(props) {

    let imgClasses;

    if (props.type === 'child') {
        imgClasses = 'quantity-type__icon icon-child'
    } else {
        imgClasses = 'quantity-type__icon'
    }

    function increaseReservationQuantity() {
        props.setReservationQuantity(props.reservationQuantity + 1);
    }

    function decreaseReservationQuantity() {
        if (props.reservationQuantity >= 1) {
            props.setReservationQuantity(props.reservationQuantity - 1);
        }
    }

    return (
        <div className='booking__quantity-wrapper'>

            <div className='quantity-wrapper__quantity-display'>
                <div className='quantity-display__quantity-type d-flex align-items-center'>
                    <img
                        src={props.image}
                        alt=""
                        className={imgClasses}
                    />
                    {props.reservationQuantity}
                </div>
            </div>
            {
                props.pickedSchedules.length > 0
                ?
                (
                    <div className='quantity-wrapper__button-row'>
                        {
                            props.reservationQuantity < 1
                                ?
                                (
                                    <Button
                                        onClick={increaseReservationQuantity}
                                        disabled={props.increaseActive}
                                    >
                                        <img src={increase} alt="" className='button__icon'/>
                                    </Button>
                                )
                                :
                                (
                                    <>
                                        <Button
                                            className='button-row__button'
                                            onClick={decreaseReservationQuantity}
                                        >
                                            <img src={decrease} alt="" className='button__icon'/>
                                        </Button>
                                        <Button
                                            className='button-row__button'
                                            onClick={increaseReservationQuantity}
                                            disabled={props.increaseActive}
                                        >
                                            <img src={increase} alt="" className='button__icon'/>
                                        </Button>
                                    </>
                                )
                        }
                    </div>
                )
                :
                (
                    <div className='quantity-wrapper__button-row'>
                        <Button disabled>
                            <img src={increase} alt="" className='button__icon'/>
                        </Button>
                    </div>
                )
            }
        </div>
    )
}

export default OneDayQuantityCounter;