import React, {useEffect} from 'react';
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import AdminLogin from "./AdminLogin";
import QRTool from "./QRTool";
import {defineUrl, tenantId} from "../../misc";
import AdminDashboard from "./AdminDashboard";

const AdminWrapper = () => {

    const history = useHistory()

    const location = useLocation();

    const {path} = useRouteMatch();

    const url = defineUrl();

    useEffect(() => {
        if (!localStorage.getItem('adminToken') && location.pathname !== '/admin/login') {
            history.push('/admin/login')
        }
        console.log(location)
    }, [location]);

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <AdminDashboard/>
            </Route>

            <Route path={`${path}/login`}>
                <AdminLogin/>
            </Route>
            <Route path={`${path}/create-qr-tool`}>
                <QRTool/>
            </Route>
        </Switch>

    );
};

export default AdminWrapper;