import React, {useEffect} from "react";
import {Link, useHistory} from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap';

function LoginSelect(props) {

    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('adminToken')) {
            history.push('/admin');
        }
        if (props.loggedIn) {
            history.push('/booking');
        }
    }, [props.loggedIn, history]);

    return (
        <Container>
            <Row>
                <Col className='booking-wrapper login-select-page'>
                    <h1>Willkommen!</h1>
                    <h3>Bitte, wählen Sie wie Sie sich anmelden möchten:</h3>
                    <div className='d-flex justify-content-around login-select-page__buttons'>
                        <Link to='/guest-login'>
                            <Button>Gast</Button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginSelect;