import React, {useEffect} from "react";
import {Button} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";

function OrderCancelled(props) {

    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem('guestId')) {
            history.push('/')
        }
    }, [history])

    return (
        <>
            <h2>It seems your order is cancelled.</h2>
            <h4>That's not a problem!</h4>
            <h4>Let's book another day!</h4>
            <Link to='/'>
                <Button>Booking</Button>
            </Link>
        </>
    )
}

export default OrderCancelled;