import React, { useState } from "react";
import {Container, Row, Col, Button, Form, Spinner} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import './LoginVerify.css';

function LoginVerify(props) {

    const [codeValue, setCodeValue] = useState('');

    const guestId = localStorage.getItem('guestId');

    const history = useHistory();

    const setCode = (event) => {
        setCodeValue(event.target.value);
    };

    const verifySuccess = () => {
        props.setLoggedIn(true);
        history.push('/booking');
    }

    const submitVerificationCode = () => {
        let code = codeValue.toString();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var graphql = JSON.stringify({
        query: "mutation {    guestVerify(        guestId: "+ guestId +",        code: \"" + code +"\"    ) }",
        variables: {}
        })
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
        };

        fetch(props.url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.errors) {
                const messageArray = [];
                result.errors.forEach(e => messageArray.push(e.message))
                alert(messageArray);
            }
            result.data.guestVerify === true ? (verifySuccess()) : (console.log(result))
        })
        .catch(error => alert(error));

    };

     if (props.email) {
         return (
            <Container>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Form
                            className='form-body d-flex flex-column'
                        >
                            <h3 className='form-body__title'>
                                Buchung.
                            </h3>
                            <p className='form-body__text'>
                                Mail mit Code wurde an <span className='fw-bolder'>{props.email}</span> gesendet. Bitte prüfen Sie Ihren Posteingang und geben Sie den Code aus dem Mail ein.
                            </p>
                            <Form.Label htmlFor="inlineFormInputName" className='form-body__label'>
                                Geben Sie Ihren Bestätigungscode ein.
                            </Form.Label>
                            <Form.Control placeholder="Verifizierungs code" className='form-body__input' onChange={setCode} />
                            {/* <Link className='form-body__create-link' to='/profile-creation'>Create account</Link> */}
                            <div className='button-row d-flex justify-content-end'>
                                {/* <Link className='dummy-link' to='/booking/room'> */}
                                    <Button className='form-body__submit-btn' onClick={submitVerificationCode}>Fortfahren</Button>
                                {/* </Link> */}
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
     } else {
         history.push('/')
         return (
             <Spinner animation="border" role="status">
                 <span className="visually-hidden">Loading...</span>
             </Spinner>
         )
     }
}

export default LoginVerify;