import React, {useEffect} from "react";
import { Spinner } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

function BookingPre(props) {

    const history = useHistory();

    useEffect(() => {
        if (!props.loggedIn) {
            history.push('/')
        }

        if (props.rooms && props.rooms.length && !localStorage.getItem('token')) {
            props.setCurrentDate(new Date());
            props.setPickedDate(new Date());
            history.push('/booking/quick-reservation');
        } else if (props.rooms && props.rooms.length && !localStorage.getItem('token')) {
            props.setCurrentDate(new Date());
            props.setPickedDate(new Date());
            history.push('/booking/members-reservation');
        }
    }, [props.rooms]);

    return (
        <div className='booking-pre-link-wrap'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default BookingPre;