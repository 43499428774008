import React, {useState} from "react";
import {Link, useHistory} from 'react-router-dom';
import {Button, Spinner} from 'react-bootstrap';
import CheckOrderCheckboxes from "./CheckOrderCheckboxes";

function CheckOrder(props) {

    const history = useHistory();

    const [allChecked, setAllChecked] = useState(false);

    const guestId = localStorage.getItem('guestId');

    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    if (props.rooms && props.pickedDate && props.pickedSchedules && props.pickedRoom) {

        const pickedRoom = props.pickedRoom;

        const pickedDay = new Date(props.pickedDate);

        const date = pickedDay.getUTCDate();

        const month = pickedDay.getUTCMonth();

        const schedulesArray = props.pickedSchedules.map(schedule => {
            return schedule.name
        });

        function getRoomName(room) {
            if (room.id === pickedRoom) {
                return room;
            }
        }

        function getSchedule([key, value], schedule) {
            if (key === schedule) {
                return value;
            }
        }

        const roomData = props.rooms.find((room) =>getRoomName(room, pickedRoom));

        const schedulesData = schedulesArray.map(schedule => {
            const scheduleDatas = Object.entries(roomData.schedules).find(([key, value]) => getSchedule([key, value], schedule));
            return (
                scheduleDatas
            )
        })

        const firstScheduleData = schedulesData[0];

        const lastScheduleData = schedulesData[schedulesData.length - 1];

        function handleReservationSubmit() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var graphql = JSON.stringify({
                query: "mutation {    " +
                    "reserveRoom( roomId: " + roomData.id + ", times: [\"" + schedulesArray.join('","') + "\"], day: \"" + pickedDay + "\" guestId: " + guestId + ", count: " + props.adultReservationQuantity + "    altCount: " + props.childReservationQuantity + "        ) }",
                variables: {}
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: graphql,
                redirect: 'follow'
            };

            fetch(props.url, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.errors) {
                        const messageArray = [];
                        result.errors.forEach(e => messageArray.push(e.message))
                        alert(messageArray);
                    } else {
                        window.open(result.data.reserveRoom.url, '_self');
                    }
                })
                .catch(error => alert(error));
        }

        return (
            <>
                <div className='check-order__information'>
                    <h1 className='information__title'>
                        Ihre Bestellung:
                    </h1>
                    <div className='information__info-line'>
                        <div className='info-line__title'>
                            Tag:
                        </div>
                        <div className='info-line__value'>
                            {date} {monthNames[month]}
                        </div>
                    </div>
                    <div className='information__info-line'>
                        <div className='info-line__title'>
                            Zeitplan:
                        </div>
                        <div className='info-line__value'>
                            {
                                props.selectFullDay === true
                                ?
                                (
                                    'Full day'
                                )
                                :
                                (
                                    firstScheduleData[1].from
                                    +
                                    ' - '
                                    +
                                    lastScheduleData[1].to
                                )
                            }
                        </div>
                    </div>
                    <div className='information__info-line'>
                        <div className='info-line__title'>
                            Reservierungs menge:
                        </div>
                        <div className='info-line__value'>
                            {props.adultReservationQuantity + props.childReservationQuantity}
                        </div>
                    </div>
                </div>
                <CheckOrderCheckboxes
                    setAllChecked={setAllChecked}
                />
                <div className="email-text">
                   (Sie erhalten nach der Bezahlung einen QR-Code per E-Mail)
                </div>
                <Link
                    to='/booking/quick-reservation'
                    onClick={props.nonFullReset}
                >
                    <Button>Zurück</Button>
                </Link>
                {/* <Link to='/booking/success' >

                </Link> */}
                <Button
                    disabled={!allChecked}
                    onClick={handleReservationSubmit}
                    className='check-order__proceed-btn'
                >
                    Zahlung durchführen
                </Button>
            </>
        )
    } else {

        history.push('/booking/members-reservation');

        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )
    }
}

export default CheckOrder;