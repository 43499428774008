import React, { useEffect, useState, useReducer } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import './Booking.css'
import CheckOrder from "./CheckOrder/CheckOrder";
import OrderSuccess from "./OrderSuccess/OrderSuccess";
import BookingPre from "./BookingPre/BookingPre";
import OneDayBooking from "./OneDayBooking/OneDayBooking";
import OrderCancelled from "./OrderCancelled/OrderCancelled";
import OrderFailed from "./OrderFailed/OrderFailed";
import useGetRooms from "../../Hooks/useGetRooms";
import {defineUrl} from "../../misc";

const initialState = {pickedSchedules: []}

const reducer = (pickedSchedulesArray, action) => {
    switch (action.type) {
        case 'ADD_SCHEDULE':
            return {
                pickedSchedules: [...pickedSchedulesArray.pickedSchedules, action.val]
            };
        case 'REMOVE_SCHEDULE':
            return {
                pickedSchedules: pickedSchedulesArray.pickedSchedules.filter((schedule) => schedule.name !== action.val)
            };
        case 'CHANGE_STATUS':
            let updatedSchedule = pickedSchedulesArray.pickedSchedules.findIndex((schedule) => schedule.name === action.val.name);
            return {
                pickedSchedules: [
                    { ...pickedSchedulesArray.pickedSchedules[updatedSchedule], full: action.val.newStatus },
                    ...pickedSchedulesArray.pickedSchedules.filter((schedule) => schedule.name !== action.val.name)
                ]
            };
        case 'CLEAR_ARRAY':
            return {
                pickedSchedules: []
            };
        default:
            return pickedSchedulesArray;
    }
};

function Booking(props) {

    //changes to test my theory
    
    let { path } = useRouteMatch();

    const url = defineUrl();

    const pickedRoom = 1;

    const [currentDate, setCurrentDate] = useState(new Date());

    const [pickedDate, setPickedDate] = useState(new Date());

    const [reservationQuantity, setReservationQuantity] = useState(0);

    const [childReservationQuantity, setChildReservationQuantity] = useState(0);

    const [adultReservationQuantity, setAdultReservationQuantity] = useState(0);

    const [increaseActive, setIncreaseActive] = useState(false);

    const [pickedSchedulesArray, dispatchSchedules] = useReducer(reducer, initialState);

    const {data:rooms, error, loading, fetchRooms} = useGetRooms(url);

    useEffect(() => {
        if (pickedSchedulesArray.pickedSchedules.length < 1) {
            setAdultReservationQuantity(0);
            setChildReservationQuantity(0);
        }
    }, [pickedSchedulesArray]);


    useEffect(() => {
        if (pickedSchedulesArray.pickedSchedules.some((schedule) => schedule.full === true)) {
            setIncreaseActive(true);
        } else {
            setIncreaseActive(false);
        }
    }, [pickedSchedulesArray]);

    function increaseDate() {
        let nextDate = new Date(pickedDate);
        nextDate.setDate(nextDate.getDate() + 1);
        setPickedDate(nextDate);
        dispatchSchedules({type: 'CLEAR_ARRAY'})
        setChildReservationQuantity(0);
        setAdultReservationQuantity(0);
    }

    function decreaseDate() {
        let today = new Date(currentDate);
        let nextDate = new Date(pickedDate);
        if (nextDate > today) {
            nextDate.setDate(nextDate.getDate() - 1);
            setPickedDate(nextDate);
            dispatchSchedules({type: 'CLEAR_ARRAY'});
            setChildReservationQuantity(0);
            setAdultReservationQuantity(0);
        }
    }

    function resetForNextBooking() {
        dispatchSchedules({type: 'CLEAR_ARRAY'});
        setReservationQuantity(0);
        setPickedDate(undefined);
    }

    function nonFullReset() {
        dispatchSchedules({type: 'CLEAR_ARRAY'});
        setChildReservationQuantity(0);
        setAdultReservationQuantity(0);
    }

    useEffect(() => {
        fetchRooms()
    }, []);


    return (
        <Container>
            <Row>
                <Col className='booking-wrapper'>
                    <Switch>

                        <Route exact path={`${path}`}>
                            <BookingPre
                                setCurrentDate={setCurrentDate}
                                setPickedDate={setPickedDate}
                                rooms={rooms}
                                loggedIn={props.loggedIn}
                            />
                        </Route>
                        <Route path={`${path}/quick-reservation`}>
                            <OneDayBooking
                                pickedRoom={pickedRoom}
                                pickedDate={pickedDate}
                                currentDate={currentDate}
                                pickedSchedules={pickedSchedulesArray.pickedSchedules}
                                reservationQuantity={reservationQuantity}
                                increaseDate={increaseDate}
                                decreaseDate={decreaseDate}
                                rooms={rooms}
                                url={url}
                                setReservationQuantity={setReservationQuantity}
                                increaseActive={increaseActive}
                                setIncreaseActive={setIncreaseActive}
                                childReservationQuantity={childReservationQuantity}
                                setChildReservationQuantity={setChildReservationQuantity}
                                adultReservationQuantity={adultReservationQuantity}
                                setAdultReservationQuantity={setAdultReservationQuantity}
                                dispatchSchedules={dispatchSchedules}
                            />
                        </Route>
                        <Route path={`${path}/pre-send-check`}>
                            <CheckOrder 
                                pickedSchedules={pickedSchedulesArray.pickedSchedules}
                                pickedRoom={pickedRoom}
                                pickedDate={pickedDate}
                                rooms={rooms}
                                url={url}
                                childReservationQuantity={childReservationQuantity}
                                adultReservationQuantity={adultReservationQuantity}
                                nonFullReset={nonFullReset}
                            />
                        </Route>
                        <Route path={`${path}/success`}>
                            <OrderSuccess
                                rooms={rooms}
                                url={url}
                                resetForNextBooking={resetForNextBooking}
                                loggedIn={props.loggedIn}
                            />
                        </Route>
                        <Route path={`${path}/failed`}>
                            <OrderFailed
                                loggedIn={props.loggedIn}
                            />
                        </Route>
                        <Route path={`${path}/cancelled`}>
                            <OrderCancelled
                                loggedIn={props.loggedIn}
                            />
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </Container>
    )
}

export default Booking;