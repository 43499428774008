import {useEffect, useState} from "react";

const useGetId = () => {
    const [guestId, setGuestId] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
        const id = localStorage.getItem('guestId');
        if (id) {
            setGuestId(id)
        }
        setLoaded(true)
    }, []);


    return {guestId, loaded}
}

export default useGetId;