import React, {useState, useEffect} from "react";
import {Form} from "react-bootstrap";

function CheckOrderCheckboxes(props) {

    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [haveCertificate, setHaveCertificate] = useState(true);

    function changeAgreeVal() {
        setAgreeToTerms(!agreeToTerms);
    }
    // function changeCertificateVal() {
    //     setHaveCertificate(!haveCertificate);
    // }

    useEffect(() => {
       if (agreeToTerms && haveCertificate) {
           props.setAllChecked(true);
       } else {
           props.setAllChecked(false);
       }
    }, [agreeToTerms, haveCertificate, props])

    return (
        <div className='check-order__agreement-checkboxes'>
            <Form.Check
                type='checkbox'
                label='Ja, ich habe die „Boulderhallenregeln" gelesen und akzeptiere diese'
                className='agreement-checkboxes__checkbox'
                value={agreeToTerms}
                onClick={changeAgreeVal}
            />
            {/*<Form.Check*/}
            {/*    type='checkbox'*/}
            {/*    label='Ich bin im Besitz eines Covid Zertifikats'*/}
            {/*    className='agreement-checkboxes__checkbox'*/}
            {/*    value={haveCertificate}*/}
            {/*    onClick={changeCertificateVal}*/}
            {/*/>*/}
        </div>
    )
}

export default CheckOrderCheckboxes;