import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import './EmaiInput.css'

const EmailInput = (props) => {

    const history = useHistory();

    const [emailValid, setEmailValid] = useState(false);
    const emailValidation = (email) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return emailRegex.test(email);
    }

    const submitHandler = () => {
        history.push('/admin/create-qr-tool/quick-reservation');
    }

    useEffect(() => {
        const isValid = emailValidation(props.emailValue);
        setEmailValid(isValid);
    }, [props.emailValue]);

    return (
        <Form className='form-body form-body__email-input' onSubmit={(e) => e.preventDefault()}>
            <h3 className='form-body__title'>
                Geben Sie die E-Mail ein, für die Sie eine Reservierung erstellen möchten
            </h3>
            <Form.Label htmlFor="inlineFormInputName" className='form-body__label'>
                Email
            </Form.Label>
            <Form.Control
                placeholder="example@mail.com"
                className='form-body__input'
                type='email'
                value={props.emailValue}
                onChange={(e) => props.setEmail(e.target.value)}
            />
            <div className="email-input__controls">
                <Button
                    disabled={!emailValid}
                    onClick={submitHandler}
                >
                    Weiter
                </Button>
                <Link className='btn' to='/admin'>Zurück</Link>
            </div>
        </Form>
    );
};

export default EmailInput;