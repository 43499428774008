import React, {useEffect, useState} from "react";
import {Button, Spinner} from 'react-bootstrap';
import {Link, useHistory, useLocation} from 'react-router-dom';
import OneDayDatePick from "./OneDayDatePick/OneDayDatePick";
import OneDaySchedules from "./OneDaySchedules/OneDaySchedules";
import OneDayQuantity from "./OneDayQuantity/OneDayQuantity";

function OneDayBooking(props) {

    const history = useHistory();

    const location = useLocation();

    const isAdminTool = location.pathname.includes('admin');

    const [isDayOff, setIsDayOff] = useState(false);

    useEffect(() => {

        const date = new Date(props.pickedDate);

        const dayOrder = date.getDay();

        if (dayOrder === 0 || dayOrder === 6) {
            setIsDayOff(true)
        } else {
            setIsDayOff(false)
        }
    }, [props.pickedDate]);

    if (props.rooms) {
        return (
            <>
                <div>
                    <h3>
                        Reservierungen
                    </h3>
                </div>
                <div className='one-day-top-row'>
                    <div className='one-day-top-row__item item-left d-flex justify-content-center'>
                        <OneDayQuantity
                            reservationQuantity={props.reservationQuantity}
                            setReservationQuantity={props.setReservationQuantity}
                            increaseActive={props.increaseActive}
                            pickedSchedules={props.pickedSchedules}
                            childReservationQuantity={props.childReservationQuantity}
                            setChildReservationQuantity={props.setChildReservationQuantity}
                            adultReservationQuantity={props.adultReservationQuantity}
                            setAdultReservationQuantity={props.setAdultReservationQuantity}
                        />
                    </div>
                    <div className='one-day-top-row__item item-right'>
                        <OneDayDatePick
                            increaseDate={props.increaseDate}
                            decreaseDate={props.decreaseDate}
                            pickedDate={props.pickedDate}
                            currentDate={props.currentDate}
                        />
                    </div>
                </div>

                {!isDayOff
                    ?
                    <>
                        <OneDaySchedules
                            pickedRoom={props.pickedRoom}
                            pickedDate={props.pickedDate}
                            rooms={props.rooms}
                            url={props.url}
                            setReservationQuantity={props.setReservationQuantity}
                            reservationQuantity={props.reservationQuantity}

                            childReservationQuantity={props.childReservationQuantity}
                            setChildReservationQuantity={props.setChildReservationQuantity}
                            adultReservationQuantity={props.adultReservationQuantity}
                            setAdultReservationQuantity={props.setAdultReservationQuantity}

                            setPickedSchedules={props.setPickedSchedules}
                            pickedSchedules={props.pickedSchedules}
                            setIncreaseActive={props.setIncreaseActive}
                            increaseActive={props.increaseActive}

                            dispatchSchedules={props.dispatchSchedules}
                        />
                        <div>
                            {
                                (props.pickedSchedules.length > 0 && props.adultReservationQuantity !== 0)
                                ||
                                (props.childReservationQuantity !== 0 && props.pickedSchedules.length > 0)
                                    ?
                                    (
                                        isAdminTool
                                            ?
                                            <Link
                                                className='admin-next-btn'
                                                to='pre-send-check'
                                            >
                                                <Button>
                                                    Weiter
                                                </Button>
                                            </Link>
                                            :
                                            <Link to='/booking/pre-send-check'>
                                                <Button>
                                                    Jetzt reservieren
                                                </Button>
                                            </Link>
                                    )
                                    :
                                    (
                                        <></>
                                    )
                            }
                            {
                                isAdminTool &&
                                <Link to='/admin/create-qr-tool'>
                                    <Button>
                                        Zurück
                                    </Button>
                                </Link>
                            }
                        </div>
                    </>
                    :
                    <div className='booking-closed'>Geschlossen</div>
                }
            </>
        )
    } else {
        history.push('/')
        return (
            <div className='booking-pre-link-wrap'>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )
    }
}

export default OneDayBooking;