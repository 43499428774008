import React, {useEffect, useState} from "react";
// import './LoginAuth.css';
import {Container, Row, Col, Button, Form, InputGroup} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import visibleIcon from "../../assets/eye-solid.svg";
import hiddenIcon from "../../assets/eye-slash-solid.svg";
import {defineUrl} from "../../misc";

function AdminLogin(props) {

    const url = defineUrl();
    const loginUrl = url.slice(0, -8);

    const history = useHistory();

    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const emailChange = (event) => {
        setEmail(event.target.value);
    };

    const passwordChange = (event) => {
        setPassword(event.target.value);
    };

    const changeShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const user = {
        email: email,
        password: password,
        tenantId: 'd0772378-7c87-44f1-aa2a-0cc1c045a821'
    };


    useEffect(() => {
        if (localStorage.getItem('adminToken')) {
            history.push('/admin/');
        }
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();
        var raw = JSON.stringify(user);
        var requestOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer'
            },
            body: raw,
            redirect: 'follow',
        };
        const response = await fetch( loginUrl + '/api/v1/auth/signin', requestOptions);
        const data = await response.json();
        if (data.status === 'success') {
            localStorage.clear();
            localStorage.setItem('adminToken', data.data.tokens.access);
            localStorage.setItem('refresh_token', data.data.tokens.refresh);
            history.push('/admin/');
        } else if (data.status === 'error') {
            window.alert(data.message.toUpperCase());
        } else {
            console.log(data);
        }
    }


    return (
        <Container>
            <Row>
                <Col className='d-flex justify-content-center'>
                    <Form
                        className='form-body d-flex flex-column'
                        onSubmit={handleSubmit}
                    >
                        <h3 className='form-body__title'>
                            Geben Sie die E-Mail-Adresse des Administrators ein.
                        </h3>
                        <Form.Label htmlFor="inlineFormInputName" className='form-body__label'>
                            Email
                        </Form.Label>
                        <Form.Control
                            placeholder="test@mail.com"
                            className='form-body__input'
                            type='email'
                            onChange={emailChange}
                        />
                        <Form.Label htmlFor="inlineFormInputName" className='form-body__label'>
                            Passwort
                        </Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="********"
                                className='form-body__input password-reset-input'
                                type={showPassword ? 'text' : 'password'}
                                onChange={passwordChange}
                            />
                            <Button
                                className='form-toggle-password-view'
                                onClick={changeShowPassword}
                            >
                                <img
                                    className='form-toggle-password-view__icon'
                                    src={ showPassword ? visibleIcon : hiddenIcon } alt=""
                                />
                            </Button>
                        </InputGroup>
                        <div className='button-row d-flex justify-content-end'>
                            <Button
                                className='form-body__submit-btn'
                                type='submit'
                                // onClick={submitEmail}
                            >
                                Fortfahren
                            </Button>
                        </div>

                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminLogin;