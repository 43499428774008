import React, {useEffect, useState, useMemo} from "react";
import {ListGroup, Spinner} from "react-bootstrap";

import ProgressLine from "./ProgressLine";

function SingleSchedule(props) {

    const [roomProgressBar, setRoomProgressBar] = useState(0);

    const [allSchedulesData ,setAllSchedulesData] = useState();

    const [alreadyReserved, setAlreadyReserved] = useState();

    const [selected, setSelected] = useState(false);

    const [full, setFull] = useState(false);

    const scheduleName = useMemo(() => {return props.scheduleName}, [props.scheduleName]);

    const fromTime = props.from.slice(0,5);

    const toTime = props.to.slice(0,5);

    const [fullDayReserves, setFullDayReserves] = useState();

    useEffect(() => {
        if (props.roomAggregates !== undefined && props.roomAggregates.length !== 0) {
            setFullDayReserves(props.roomAggregates[0].reservations.total)
        } else {
            setFullDayReserves(0);
        }

    }, [props.roomAggregates])

    useEffect(() => {
        if (props.roomAggregates !== undefined && props.roomAggregates.length !== 0) {
            setAllSchedulesData(Object.entries(props.roomAggregates[0].reservations.schedules));
        } else {
            setAllSchedulesData(0);
        }
    }, [props.roomAggregates]);

    useEffect(() => {

        function findSchedule(schedule) {
            if (scheduleName === schedule[0]) {
                return schedule;
            }
        }

        if ( allSchedulesData !== undefined && allSchedulesData.length > 0) {
            const singleScheduleData = allSchedulesData.find(schedule => findSchedule(schedule, scheduleName));
            if (singleScheduleData !== undefined) {
                setAlreadyReserved(singleScheduleData[1]);
            } else {
                setAlreadyReserved(0);
            }
        } else {
            setAlreadyReserved(0);
        }
    }, [scheduleName, allSchedulesData])

    useEffect(() => {
        if (selected === true) {
            const totalAmountAlreadyReserved = Number(alreadyReserved + props.adultReservationQuantity + props.childReservationQuantity + fullDayReserves);
            setRoomProgressBar(totalAmountAlreadyReserved);
        } else {
            setRoomProgressBar(alreadyReserved + fullDayReserves);
        }
    }, [alreadyReserved, props.adultReservationQuantity, props.childReservationQuantity, selected, fullDayReserves])

    useEffect(() => {
        if (selected === true) {
            if (props.capacity === roomProgressBar) {
                setFull(true);
            } else if ( props.capacity < roomProgressBar) {
                setSelected(false);
                alert("You can't pick this schedule. It doesn't have enough free space.")
            } else {
                setFull(false);
            }
        } else {
            setFull(false);
        }
    }, [props.capacity, roomProgressBar, selected, setFull])

    useEffect(() => {
        if (selected === true) {
            let schedule = {};
            schedule['name'] = props.scheduleName;
            schedule['full'] = full;
            props.dispatchSchedules({type: 'ADD_SCHEDULE', val: schedule});
        } else if (selected === false) {
            props.dispatchSchedules({type: 'REMOVE_SCHEDULE', val: props.scheduleName});
        }
    }, [selected])

    useEffect(() => {
        if (selected === true) {
            props.dispatchSchedules({type: 'CHANGE_STATUS', val: {newStatus: full, name: props.scheduleName}});
        }
    }, [full])

    useEffect(() => {
        setSelected(false);
    }, [props.pickedDate])

    function selectSchedule() {
        setSelected(!selected);
        if (props.reservationQuantity < 1) {
            props.setReservationQuantity(1);
        }
    }

    return (
        <ListGroup.Item
            className='d-flex single-schedule'
            active={selected}
            onClick={selectSchedule}
        >
            <input
                className='single-schedule__checkbox'
                type="checkbox"
                checked={selected}
                readOnly
            />
            <div className='single-schedule__time'>
                <span>{fromTime}</span>
                <span className='connect-line'> - </span>
                <span>{toTime}</span>
            </div>
            {
                props.loaded
                    ?
                    (
                        <ProgressLine
                            reservationQuantity={props.reservationQuantity}
                            capacity={props.capacity}
                            roomProgressBar={roomProgressBar}
                            full={full}
                        />
                    )
                    :
                    (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )
            }

        </ListGroup.Item>
    )
}

export default SingleSchedule;