import React, { useEffect, useState, useReducer } from "react";
import {Container, Row, Col, Spinner} from "react-bootstrap";
import {Switch, Route, useRouteMatch, useLocation, useHistory} from "react-router-dom";
import '../Booking/Booking.css'
import OneDayBooking from "../Booking/OneDayBooking/OneDayBooking";
import {defineUrl} from "../../misc";
import EmailInput from "./QRToolComponents/EmailInput";
import QRPreSendCheck from "./QRToolComponents/QrPreSendCheck";
import useGetRooms from "../../Hooks/useGetRooms";

const initialState = {pickedSchedules: []}

const reducer = (pickedSchedulesArray, action) => {
    switch (action.type) {
        case 'ADD_SCHEDULE':
            return {
                pickedSchedules: [...pickedSchedulesArray.pickedSchedules, action.val]
            };
        case 'REMOVE_SCHEDULE':
            return {
                pickedSchedules: pickedSchedulesArray.pickedSchedules.filter((schedule) => schedule.name !== action.val)
            };
        case 'CHANGE_STATUS':
            let updatedSchedule = pickedSchedulesArray.pickedSchedules.findIndex((schedule) => schedule.name === action.val.name);
            return {
                pickedSchedules: [
                    { ...pickedSchedulesArray.pickedSchedules[updatedSchedule], full: action.val.newStatus },
                    ...pickedSchedulesArray.pickedSchedules.filter((schedule) => schedule.name !== action.val.name)
                ]
            };
        case 'CLEAR_ARRAY':
            return {
                pickedSchedules: []
            };
        default:
            return pickedSchedulesArray;
    }
};

function QRTool(props) {

    //changes to test my theory

    const url = defineUrl();

    let { path } = useRouteMatch();

    const location = useLocation();

    const history = useHistory()

    const pickedRoom = 1;

    const [email, setEmail] = useState('')

    const [currentDate] = useState(new Date());

    const [pickedDate, setPickedDate] = useState(new Date());

    const [reservationQuantity, setReservationQuantity] = useState(0);

    const [childReservationQuantity, setChildReservationQuantity] = useState(0);

    const [adultReservationQuantity, setAdultReservationQuantity] = useState(0);

    const [increaseActive, setIncreaseActive] = useState(false);

    const [pickedSchedulesArray, dispatchSchedules] = useReducer(reducer, initialState);

    const {data:rooms, error, loading, fetchRooms} = useGetRooms(url);

    function increaseDate() {
        let nextDate = new Date(pickedDate);
        nextDate.setDate(nextDate.getDate() + 1);
        setPickedDate(nextDate);
        dispatchSchedules({type: 'CLEAR_ARRAY'})
        setChildReservationQuantity(0);
        setAdultReservationQuantity(0);
    }

    function decreaseDate() {
        let today = new Date(currentDate);
        let nextDate = new Date(pickedDate);
        if (nextDate > today) {
            nextDate.setDate(nextDate.getDate() - 1);
            setPickedDate(nextDate);
            dispatchSchedules({type: 'CLEAR_ARRAY'});
            setChildReservationQuantity(0);
            setAdultReservationQuantity(0);
        }
    }

    useEffect(() => {
        fetchRooms()
    }, []);

    useEffect(() => {
        if (!email && location.pathname !== '/admin/create-qr-tool') {
            history.push('/admin/create-qr-tool')
        }
    }, [location]);

    useEffect(() => {
        if (pickedSchedulesArray.pickedSchedules.length < 1) {
            setAdultReservationQuantity(0);
            setChildReservationQuantity(0);
        }
    }, [pickedSchedulesArray]);


    useEffect(() => {
        if (pickedSchedulesArray.pickedSchedules.some((schedule) => schedule.full === true)) {
            setIncreaseActive(true);
        } else {
            setIncreaseActive(false);
        }
    }, [pickedSchedulesArray]);

    return (
        <Container>
            <Row>
                <Col className='booking-wrapper'>
                    {
                        rooms ?
                            <Switch>
                                <Route exact path={`${path}`}>
                                    <EmailInput
                                        emailValue={email}
                                        setEmail={setEmail}
                                    />
                                </Route>
                                <Route path={`${path}/quick-reservation`}>
                                    <OneDayBooking
                                        pickedRoom={pickedRoom}
                                        pickedDate={pickedDate}
                                        currentDate={currentDate}
                                        pickedSchedules={pickedSchedulesArray.pickedSchedules}
                                        reservationQuantity={reservationQuantity}
                                        increaseDate={increaseDate}
                                        decreaseDate={decreaseDate}
                                        rooms={rooms}
                                        url={url}
                                        setReservationQuantity={setReservationQuantity}
                                        increaseActive={increaseActive}
                                        setIncreaseActive={setIncreaseActive}
                                        childReservationQuantity={childReservationQuantity}
                                        setChildReservationQuantity={setChildReservationQuantity}
                                        adultReservationQuantity={adultReservationQuantity}
                                        setAdultReservationQuantity={setAdultReservationQuantity}
                                        dispatchSchedules={dispatchSchedules}
                                    />
                                </Route>
                                <Route path={`${path}/pre-send-check`}>
                                    <QRPreSendCheck
                                        email={email}
                                        pickedSchedules={pickedSchedulesArray.pickedSchedules}
                                        pickedRoom={pickedRoom}
                                        pickedDate={pickedDate}
                                        rooms={rooms}
                                        url={url}
                                        childReservationQuantity={childReservationQuantity}
                                        adultReservationQuantity={adultReservationQuantity}
                                    />
                                </Route>
                            </Switch>
                            :
                            <div className='booking-pre-link-wrap'>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                    }

                </Col>
            </Row>
        </Container>
    )
}

export default QRTool;