import React from "react";
import {
    Container, Row, Col, Dropdown, Button
    // Button
} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import './NavigationRow.css';
import climbingLogo from '../../assets/logo.svg';

function NavigationRow(props) {

    const history = useHistory();

    function logout(event) {
        event.preventDefault();
        localStorage.clear('token');
        localStorage.clear('');
        props.setLoggedIn(false);
        history.push('/')
    }

    const handleLogoClick = () => {
        const destination = localStorage.getItem('adminToken') ? '/admin' : '/';
        console.log(destination);
        history.push(destination);
    }

    return (
        <Container>
            <Row>
                <Col className='navigation-menu d-flex justify-content-end'>
                    <Button className='d-flex justify-content-center navigation-menu__logos-wrap' onClick={handleLogoClick}>
                        <img src={climbingLogo} alt="" className='navigation-menu__logo'/>
                    </Button>
                    <div className='d-flex align-items-center'>
                        {props.loggedIn === true &&
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                Menu
                            </Dropdown.Toggle>
                            {
                                props.loggedIn && localStorage.getItem('token') &&

                                (
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            as={Link}
                                            to={'/profile'}
                                        >
                                            Profile
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            to={'/booking'}
                                        >
                                            Booking
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={logout}>Abmelden</Dropdown.Item>
                                    </Dropdown.Menu>
                                )
                            }
                            {
                                props.loggedIn && localStorage.getItem('guestId') &&

                                (
                                <Dropdown.Menu>

                                    <Dropdown.Item
                                        as={Link}
                                        to={'/booking/quick-reservation'}
                                    >
                                        Buchung
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as={Link}
                                        to={'/booking/success'}
                                    >
                                        Reservations and QR
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={logout}>Abmelden</Dropdown.Item>
                                </Dropdown.Menu>
                                )
                            }
                        </Dropdown>
                        }
                    </div>

                </Col>
            </Row>
        </Container>
    )
}

export default NavigationRow;