import React from "react";
import {Link, useHistory} from 'react-router-dom';
import {Button, Spinner} from 'react-bootstrap';
import './QRPreSendCheck.css'
import {tenantId} from "../../../misc";

function QRPreSendCheck(props) {

    const history = useHistory();

    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    if (props.rooms && props.pickedDate && props.pickedSchedules && props.pickedRoom) {

        const pickedRoom = props.pickedRoom;

        const pickedDay = new Date(props.pickedDate);

        const date = pickedDay.getUTCDate();

        const month = pickedDay.getUTCMonth();

        const schedulesArray = props.pickedSchedules.map(schedule => {
            return schedule.name
        });

        function getRoomName(room) {
            if (room.id === pickedRoom) {
                return room;
            }
        }

        function getSchedule([key, value], schedule) {
            if (key === schedule) {
                return value;
            }
        }

        const roomData = props.rooms.find((room) => getRoomName(room, pickedRoom));

        const schedulesData = schedulesArray.map(schedule => {
            const scheduleDatas = Object.entries(roomData.schedules).find(([key, value]) => getSchedule([key, value], schedule));
            return (
                scheduleDatas
            )
        })

        const firstScheduleData = schedulesData[0];

        const lastScheduleData = schedulesData[schedulesData.length - 1];

        function handleReservationSubmit() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var graphql = JSON.stringify({
                query:  "mutation {    " +
                    "makeGuestCreateRegistrationAndQRCode(email: \""+ props.email +"\", tenantId: \"" + tenantId + "\" roomId: " + roomData.id + ", times: [\"" + schedulesArray.join('","') + "\"], day: \"" + pickedDay + "\", count: " + props.adultReservationQuantity + "    altCount: " + props.childReservationQuantity + "        ) }",
                variables: {}
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: graphql,
                redirect: 'follow'
            };

            fetch(props.url, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.errors) {
                        const messageArray = [];
                        result.errors.forEach(e => messageArray.push(e.message))
                        alert(messageArray);
                    } else {
                        const email = result.data.makeGuestCreateRegistrationAndQRCode.guest.email;
                        window.alert(`Reservation created and QR was sent to email ${email}`)
                        history.push('/admin')
                    }
                })
                .catch(error => alert(error));
        }

        return (
            <>
                <div className='check-order__information'>
                    <h1 className='information__title'>
                        Daten zur Reservierung:
                    </h1>
                    <div className='information__info-line'>
                        <div className='info-line__title'>
                            Email:
                        </div>
                        <div className='info-line__value'>
                            {props.email}
                        </div>
                    </div>
                    <div className='information__info-line'>
                        <div className='info-line__title'>
                            Tag:
                        </div>
                        <div className='info-line__value'>
                            {date} {monthNames[month]}
                        </div>
                    </div>
                    <div className='information__info-line'>
                        <div className='info-line__title'>
                            Zeitplan:
                        </div>
                        <div className='info-line__value'>
                            {
                                props.selectFullDay === true
                                    ?
                                    (
                                        'Full day'
                                    )
                                    :
                                    (
                                        firstScheduleData[1].from
                                        +
                                        ' - '
                                        +
                                        lastScheduleData[1].to
                                    )
                            }
                        </div>
                    </div>
                    <div className='information__info-line'>
                        <div className='info-line__title'>
                            Reservierungs menge:
                        </div>
                        <div className='info-line__value'>
                            {props.adultReservationQuantity + props.childReservationQuantity}
                        </div>
                    </div>
                </div>
                <Button
                    onClick={handleReservationSubmit}
                    className='check-order__proceed-btn'
                >
                    Reservierung erstellen
                </Button>
                <Link
                    to='quick-reservation'
                >
                    <Button>Zurück</Button>
                </Link>
            </>
        )
    } else {

        history.push('/booking/members-reservation');

        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )
    }
}

export default QRPreSendCheck;