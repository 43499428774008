import React, {useEffect} from "react";
import './LoginAuth.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

function LoginAuth(props) {

    const history = useHistory();

    const submitEmail = (event) => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let emailValue = props.email.toString();

        var graphql = JSON.stringify({
        query: "mutation {    guestRegister(        email: \"" + emailValue +"\",        tenantId: \"d0772378-7c87-44f1-aa2a-0cc1c045a821\"    ) {         id        email        code        verified    }}",
        variables: {}
        })
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
        };

        fetch(props.url, requestOptions)
            .then(response => response.json())
            .then(result =>
            {
                if ('errors' in result) {
                    const messageArray = [];
                    result.errors.forEach(e => messageArray.push(e.message))
                    alert(messageArray);
                    return
                }
                history.push('/verify');
                localStorage.setItem('guestId', result.data.guestRegister.id);
            })
            .catch(error => alert(error));
    };


    useEffect(() => {
    }, []);

    return (
        <Container>
            <Row>
                <Col className='d-flex justify-content-center'>
                    <Form 
                        className='form-body d-flex flex-column'
                        onChange={props.setEmailValue}
                        onSubmit={submitEmail}
                    >
                        <h3 className='form-body__title'>
                            Geben Sie Ihre E-Mail-Adresse ein.
                        </h3>
                        <Form.Label htmlFor="inlineFormInputName" className='form-body__label'>
                            E-mail
                        </Form.Label>
                        <Form.Control
                            placeholder="random@email.com"
                            className='form-body__input'
                            type='email'
                            required
                        />
                        <div className='button-row d-flex justify-content-end'>
                            <Button
                                className='form-body__submit-btn'
                                type='submit'
                            >
                                Fortfahren
                            </Button>
                        </div>
                        
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginAuth;