import React, {useEffect, useState} from "react";
import { ListGroup } from "react-bootstrap";
import SingleSchedule from "./SingleSchedule";
import './OneDaySchedules.css';


function OneDaySchedules(props) {

    const [roomAggregates, setRoomAggregates] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var graphql = JSON.stringify({
            query: "{    getRoomAggregates(        roomId: " + props.pickedRoom + ",        day: \""+ props.pickedDate +"\"    ) {         id        reservations        day    }}",
            variables: {}
        })
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: graphql,
            redirect: 'follow'
        };

        fetch(props.url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.errors) {
                    const messageArray = [];
                    result.errors.forEach(e => messageArray.push(e.message))
                    alert(messageArray);
                } else {
                    setRoomAggregates(result.data.getRoomAggregates);
                    setLoaded(true);
                }
                // console.log(result.data);
            })
            .catch(error => alert(error));

    }, [props.pickedRoom, props.url, props.pickedDate]);

    const pickedRoomId = props.pickedRoom;

    function getRoom(room, pickedRoomId) {
        if (room.id === pickedRoomId) {
            return room;
        }
    }

    const pickedRoom = props.rooms.find((room) => getRoom(room, pickedRoomId)).schedules;

    return (
        <ListGroup className='schedules__list'>
            {
                Object.entries(pickedRoom).map(([key, value]) => (
                    <SingleSchedule
                        key={key}
                        scheduleName={key}
                        value={value}
                        from={value.from}
                        to={value.to}
                        capacity={value.capacity}
                        pickedDate={props.pickedDate}
                        pickedRoom={props.pickedRoom}
                        url={props.url}
                        setReservationQuantity={props.setReservationQuantity}
                        reservationQuantity={props.reservationQuantity}
                        roomAggregates={roomAggregates}
                        loaded={loaded}
                        pickedSchedules={props.pickedSchedules}
                        setPickedSchedules={props.setPickedSchedules}
                        setIncreaseActive={props.setIncreaseActive}
                        increaseActive={props.increaseActive}

                        childReservationQuantity={props.childReservationQuantity}
                        setChildReservationQuantity={props.setChildReservationQuantity}
                        adultReservationQuantity={props.adultReservationQuantity}
                        setAdultReservationQuantity={props.setAdultReservationQuantity}

                        dispatchSchedules={props.dispatchSchedules}
                    />
                ))
            }
        </ListGroup>
    )
}
// morning morning
export default OneDaySchedules;