import {useState} from "react";
import {tenantId} from "../misc";

const useGetRooms = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchRooms = () => {
        setLoading(true)

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let graphql = JSON.stringify({
            query: "{    getRooms(        tenantId: \"" + tenantId + "\"    ) " +
                "{         id        name        capacity        price        altPrice        schedules    }" +
                "}",
            variables: {}
        })
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: graphql,
            redirect: 'follow'
        };


        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.errors) {
                    const errors = []
                    result.errors.forEach(e => errors.push(e.message))
                    setError(errors)
                } else {
                    setData(result.data.getRooms)
                }
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false))
    }


    return {data, error, loading, fetchRooms}
}

export default useGetRooms;