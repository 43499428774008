import React from "react";
import {ProgressBar} from "react-bootstrap";

function ProgressLine(props) {

    return (
        <>
            {
                props.roomProgressBar > 0 ?
                    (
                        <ProgressBar
                            min='0'
                            max={props.capacity}
                            now={props.roomProgressBar}
                            capacity={props.capacity}
                            label={(props.roomProgressBar + '/' + props.capacity)}
                            className='single-schedule__progress-bar'
                        />
                    )
                    :
                    (
                        <ProgressBar
                            min='0'
                            max={props.capacity}
                            now={props.capacity}
                            capacity={props.capacity}
                            label={(props.roomProgressBar + '/' + props.capacity)}
                            className='single-schedule__progress-bar progress-bar-empty'
                        />
                    )
            }

        </>

    )
}

export default ProgressLine;