import React from "react";
import './OneDayQuantity.css';
import person from '../../../../assets/male-solid.svg';
import child from  '../../../../assets/child-solid.svg';
import OneDayQuantityCounter from "./OneDayQuantityCounter";

function OneDayQuantity(props) {

    return (
        <>
            <OneDayQuantityCounter
                image={person}
                reservationQuantity={props.adultReservationQuantity}
                setReservationQuantity={props.setAdultReservationQuantity}
                pickedSchedules={props.pickedSchedules}
                increaseActive={props.increaseActive}
                type='adult'
            />
            <OneDayQuantityCounter
                image={child}
                reservationQuantity={props.childReservationQuantity}
                setReservationQuantity={props.setChildReservationQuantity}
                pickedSchedules={props.pickedSchedules}
                increaseActive={props.increaseActive}
                type='child'
            />
        </>
    )
}

export default OneDayQuantity;