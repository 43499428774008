import './App.css';
import pkjJson from '../../../package.json'
import React, {useState,
  useEffect
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, Link,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Booking from "../Booking/Booking";
import LoginAuth from '../LoginAuth/LoginAuth';
import NavigationRow from '../NavigationRow/NavigationRow';
import LoginVerify from '../LoginVerify/LoginVerify';
import LoginSelect from "../LoginSelect/LoginSelect";
import AdminWrapper from "../AdminPart/AdminWrapper";
import {tenantId, defineUrl} from "../../misc";


function App() {

  const url = defineUrl();

  const [email, setEmail] = useState('');

  const [loggedIn, setLoggedIn] = useState(false);


  const setEmailValue = (event) => {
    setEmail(event.target.value);
  };

  const changeLogStatus = () => {
    setLoggedIn(true);
  };

  useEffect(() => {
    if (localStorage.getItem('guestId')) {
      setLoggedIn(true);
    } else if (localStorage.getItem('token')) {
      setLoggedIn(true);
    }
  }, [])


  return (
    <div className="App">
      <Router>
        <NavigationRow
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
        />
        <Switch>
          <Route exact path='/'>
            <LoginSelect
                loggedIn={loggedIn}
            />
          </Route>
          <Route exact path='/guest-login'>
            <LoginAuth
                changeLogStatus={changeLogStatus}
                url={url}
                loggedIn={loggedIn}
                setEmailValue={setEmailValue}
                email={email}
            />
          </Route>
          <Route path='/booking'>
            <Booking
                tenantId={tenantId}
                url={url}
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
            />
          </Route>
          <Route path='/verify'>
            <LoginVerify
                setLoggedIn={setLoggedIn}
                url={url}
                email={email}
            />
          </Route>
          <Route path='/admin'>
            <AdminWrapper/>
          </Route>
        </Switch>
        <div className="container">
          <div className="row">
            <div className="col-12 justify-content-center">
              <div
                  className='ver'
              >
                <Link to='/admin'>
                  v{pkjJson.version}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
